import * as arrayUtils from "../ArrayUtils";
import moment from "moment";
import productCategoryConfigurationData from "../../data/productCategoryConfigurationData";
import { isNullEmptyOrUndefined, convertToSentenceCase } from "../StringUtils";

/**
 * Removes the sites core pages (such as 404's and login pages) from the provided site page edge data.
 *
 * @param {Array} allSitePageEdges An array of edges which each contain a node representing a page in the application (both mdx and jsx pages).
 * @returns {Array} The filtered array with the core page edges removed.
 */
export const removeCoreSitePagesFrom = (allSitePageEdges) => {
  if (!arrayUtils.isValid(allSitePageEdges)) {
    throw new Error("No Site Page Edge data to remove core site pages from.");
  }

  const corePagesToExclude = ["/dev-404-page/", "/404/", "/app/", "/", "/premium/", "/404.html", "/sitemap/", "/smartprices/"];

  return allSitePageEdges.filter((edge) => !corePagesToExclude.includes(edge.node.path));
};

/**
 * Returns an array containing the Product Page related links.
 *
 * @param {Array} allSitePageEdges An array of edges which each contain a node representing a page in the application (both mdx and jsx pages).
 * @returns {Array} The filtered array with only the product page edges grouped by product category.
 */
export const getProductPageArrayFrom = (allSitePageEdges) => {
  if (!arrayUtils.isValid(allSitePageEdges)) {
    throw new Error("No Site Page Edge data to get product page array from");
  }

  const { productCategoryConfigurations } = productCategoryConfigurationData;
  const productPageArray = [];

  allSitePageEdges.forEach((edge) => {
    const { path } = edge.node;
    const productCategoryName = path.substring(1, path.length - 1).split("/")[0];
    const isProductCategory = productCategoryConfigurations.find((c) => c.category.toLowerCase() === productCategoryName.toLowerCase());
    if (isProductCategory) {
      productPageArray.push({ productCategory: productCategoryName, data: edge });
    }
  });

  return productPageArray.sort(function (a, b) {
    if (a.data.node.context.frontmatter.menuText < b.data.node.context.frontmatter.menuText) return -1;
    if (a.data.node.context.frontmatter.menuText > b.data.node.context.frontmatter.menuText) return 1;
    return 0;
  });
};

/**
 * Generates an Array containing the product category group names from the provided product page data.
 *
 * @param {Array} productPages An array containing the list of product pages.
 * @returns{Array} An array of distinct product category names.
 */
export const getProductCategoriesFrom = (productPages) => {
  if (arrayUtils.isValid(productPages)) {
    return Array.from(new Set(productPages.map((m) => m.productCategory))).sort();
  }

  throw new Error("Null or empty productPage array, unable to create distinct product category list");
};

/**
 * Generates an Array containing the Blog Post related links
 *
 * @param {Array} allSitePageEdges An array of edges which each contain a node representing a page in the application (both mdx and jsx pages).
 * @returns {Array} The filtered array with only the blog page edges grouped by month and year of post.
 */
export const getBlogPageArrayFrom = (allSitePageEdges) => {
  if (!arrayUtils.isValid(allSitePageEdges)) {
    throw new Error("No Site Page Edge data to get blog page array from");
  }

  const blogPageArray = [];

  allSitePageEdges.forEach((edge) => {
    const { path } = edge.node;
    const isBlogPost = path.substring(1, path.length - 1).split("/")[0] === "blog";
    if (isBlogPost && edge.node.context.frontmatter) {
      const blogDate = edge.node.context.frontmatter.date;
      const blogCategory = edge.node.context.frontmatter.blogCategory;
      blogPageArray.push({ blogCategory: blogCategory, blogGroup: getMonthYearPublishedFrom(blogDate), data: edge });
    }
  });

  return blogPageArray.sort(function (a, b) {
    return new Date(a.data.node.context.frontmatter.date) - new Date(b.data.node.context.frontmatter.date);
  });
};

/**
 * Generates a date string (formatted as month year) for the provided blog post date.
 *
 * @param {String} blogPost A blog post date string.
 * @returns {String} A string containing the formatted date.
 */
const getMonthYearPublishedFrom = (blogPostDate) => {
  if (isNullEmptyOrUndefined(blogPostDate)) {
    throw new Error("Null or empty blogPost object, unable to get published month and year");
  }

  const DATE_FORMAT = "MMMM YYYY";
  return moment(blogPostDate).format(DATE_FORMAT);
};

/**
 * Generates an array containing the blog post grouped date information.
 *
 * @param {Array} productPages An array containing the blog post related links.
 * @returns{Array} Containing a distinct list of teh blog post grouped date information.
 */
export const getBlogDateArrayFrom = (blogPages) => {
  if (arrayUtils.isValid(blogPages)) {
    return Array.from(new Set(blogPages.map((m) => getMonthYearPublishedFrom(m.blogGroup)))).sort(function (a, b) {
      return new Date(a) - new Date(b);
    });
  }

  throw new Error("Null Or Empty productPage Array, Unable To Create Distinct Product Category List");
};

/**
 * Generates an array containing all non excluded, product related or blog pages.
 *
 * @param {Array} allSitePageEdges An array of edges which each contain a node representing a page in the application (both mdx and jsx pages).
 * @returns{Array} An array containing the filtered data results.
 */
export const getSitePageArrayFrom = (allSitePageEdges) => {
  if (!arrayUtils.isValid(allSitePageEdges)) {
    throw new Error("No page edge data to generate product page array from");
  }

  const { productCategoryConfigurations } = productCategoryConfigurationData;

  const sitePageArray = [];

  allSitePageEdges.forEach((edge) => {
    const { path } = edge.node;
    const isBlogPost = path.substring(1, path.length - 1).split("/")[0] === "blog";
    const productCategoryName = path.substring(1, path.length - 1).split("/")[0];
    const isProductCategory = productCategoryConfigurations.find((c) => c.category === productCategoryName);

    if (!isBlogPost && !isProductCategory) {
      sitePageArray.push(edge);
    }
  });

  return sitePageArray.sort();
};

/**
 * Takes a nodeId and returns a Sentence Cased parsed, page name.
 *
 * @param {String} nodeId A node id value.
 * @returns{String} The processed node id, stripped of the / and - characters, in sentence case.
 */
export const getFormattedSitePageLinkTextFrom = (nodeId) => {
  if (isNullEmptyOrUndefined(nodeId)) {
    throw new Error("Null, empty or undefined nodeId. Unable to get formatted site page link.");
  }

  let buffer = nodeId.replace("SitePage", "");
  buffer = buffer.split("/")[1];
  buffer = buffer.replace(/[-]/g, " ");

  return convertToSentenceCase(buffer);
};

/**
 * Generates an array containing the blog post grouped the defined blog category group.
 *
 * @param {Array} productPages An array containing the blog post related links.
 * @returns{Array} Containing a distinct list of the blog post grouped by category information.
 */
export const getBlogCategoryArrayFrom = (blogPages) => {
  if (arrayUtils.isValid(blogPages)) {
    return Array.from(new Set(blogPages.map((m) => convertToSentenceCase(m.blogCategory)))).sort();
  }

  throw new Error("Null Or Empty blogPage Array, Unable To Create Distinct Blog Category List");
};
